import React, { useState } from "react"
import { Link } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import * as queryString from "query-string"

const SearchBar = ({ query, setQuery, navigate }) => (
  <div className="searchbar">
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
    </svg>
    <input
      id="search"
      type="search"
      placeholder="Search all posts"
      value={query}
      onChange={e => {
        navigate(e.target.value ? `/blog/?search=${e.target.value}` : "/blog/")
        setQuery(e.target.value)
      }}
    />
  </div>
)

const SearchedPosts = ({ results }) =>
  results.length > 0 ? (
    <div className="posts-wrapper">
      {results.map(node => {
        const date = node.date
        const title = node.title || node.slug
        const description = node.description
        const excerpt = node.excerpt
        const slug = node.slug

        return (
          <div key={slug} className="blog-card">
            <Link to={`/blog${slug}`}>
              <div className="blog-card-tag">Code</div>
              <h3 className="blog-card-title">{title}</h3>
              <p
                className="blog-card-content"
                dangerouslySetInnerHTML={{
                  __html: description || excerpt,
                }}
              />
              <p className="blog-card-date">{date}</p>
            </Link>
          </div>
        )
      })}
    </div>
  ) : (
    <p className="no-result">
      <span role="img" aria-label="emoji grin">
        😬
      </span>{" "}
      Sorry, couldn't find any posts matching this search.
    </p>
  )

const AllPosts = ({ posts }) => (
  <div className="posts-wrapper">
    {posts.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug
      return (
        <div key={node.fields.slug} className="blog-card">
          <Link to={`/blog${node.fields.slug}`}>
            <div className="blog-card-tag">Code</div>
            <h3 className="blog-card-title">{title}</h3>
            <p
              className="blog-card-content"
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.excerpt,
              }}
            />
            <p className="blog-card-date">{node.frontmatter.date}</p>
          </Link>
        </div>
      )
    })}
  </div>
)

const SearchPosts = ({ posts, localSearchBlog, location, navigate }) => {
  const { search } = queryString.parse(location.search)
  const [query, setQuery] = useState(search || "")

  const results = useFlexSearch(
    query,
    localSearchBlog.index,
    JSON.parse(localSearchBlog.store)
  )

  return (
    <>
      <div className="searchbar-posts">
        <SearchBar query={query} setQuery={setQuery} navigate={navigate} />
      </div>
      {query ? <SearchedPosts results={results} /> : <AllPosts posts={posts} />}
    </>
  )
}

export default SearchPosts

import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import SearchPosts from "../components/searchPosts"
import Navbar from "../components/navbar"
import { Footer } from "../components/footer"

class Blog extends React.Component {
  render() {
    const { data, navigate, location } = this.props
    const posts = data.allMdx.edges
    const localSearchBlog = data.localSearchBlog

    return (
      <>
        <Navbar />
        <SEO title="All posts" />
        <section className="section-wrapper bg-light-beige">
          <div className="section column-direction">
            <SearchPosts
              posts={posts}
              localSearchBlog={localSearchBlog}
              navigate={navigate}
              location={location}
            />
          </div>
        </section>
        <Footer />
      </>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
